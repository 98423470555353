import { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import { TextField } from '@mui/material';

import SmallDialog from 'components/SmallDialog';

export default function ContextMenu({
  contextMenu,
  handleClose,
  rename,
  loadPrompt,
  handleMarkAsFinal,
  handleDelete,
}) {
  const [nameDialogIsOpen, setnameDialogIsOpen] = useState(false);

  const [nameData, setnameData] = useState();

  const nameRef = useRef();

  useEffect(() => {
    if (nameData) {
      rename(nameRef.current.value);
      setnameData(null);
      handleClose();
    }
  }, [nameData]);

  return (
    <>
      <Paper sx={{ width: 320 }}>
        <Menu
          id="basic-menu"
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          open={contextMenu !== null}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          <MenuItem>
            <ListItemText onClick={() => setnameDialogIsOpen(true)}>
              Rename
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={loadPrompt}>Load</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={handleMarkAsFinal}>
              Mark as final
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={handleDelete}>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </Paper>
      <SmallDialog
        title="set order name"
        content=""
        open={nameDialogIsOpen}
        setOpen={setnameDialogIsOpen}
        setData={setnameData}
        confirmButton="rename"
        cancelButton="cancel"
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="order name"
          name="orderName"
          autoComplete="100"
          inputRef={nameRef}
          multiline={false}
          autoFocus
          className="ring-0"
        />
      </SmallDialog>
    </>
  );
}
