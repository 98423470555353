import { useState, useEffect, useRef, useMemo, memo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { useCms } from '../../utils/CMS';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

import { FixedSizeList, VariableSizeList, areEqual } from 'react-window';
import memoize from 'memoize-one';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LoraEditMenu from './LoraEditMenu';
import Dropdown from 'components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLoraStrength,
  setLoadedPrompt,
  setSelectedLorasCount,
  setLoras,
  setSelectedFluxLoras,
} from 'state/generate';
import CheckDropdown from 'components/CheckDropdown';

const Row = ({ data, index, style }) => {
  // Data passed to List as "itemData" is available as props.data
  const { loras, handleSelectLora, handleStrengthUpdate, handleRemoveLora } =
    data;
  const lora = loras[index];

  return (
    <ListItem
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '20px',
      }}
      divider
      style={style}
    >
      <Checkbox
        checked={lora.selected}
        onChange={(e) => handleSelectLora(e.target.checked, lora)}
        sx={{ width: '40px' }}
      />
      <img
        src={`data:image/jpeg;base64,${lora.mini_thumb}`}
        alt=""
        width="100"
        key={index}
        style={{ cursor: 'pointer' }}
        loading="lazy"
      />
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '0px',
        }}
      >
        <ListItemText
          sx={{ width: '400px', justifySelf: 'flex-start' }}
          primary={lora.hname != '' ? lora.hname : lora.name}
          secondary={lora.name != '' ? lora.name : ''}
        ></ListItemText>
        <ListItemText
          sx={{ width: '400px', justifySelf: 'flex-start' }}
          secondary={lora.sex}
        ></ListItemText>
        <ListItemText
          sx={{
            width: '400px',
            justifySelf: 'flex-start',
            marginTop: '-8px',
          }}
          secondaryTypographyProps={{ color: '#fecd27' }}
          secondary={lora.user}
        ></ListItemText>
      </ListItem>

      {/* <Typography >
      {lora.name}
    </Typography> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          type="number"
          margin="normal"
          id="loraStrength"
          label="strength"
          name="strength"
          value={lora.strength}
          onChange={(e) => handleStrengthUpdate(e.target.value, lora)}
          className="ring-0"
          sx={{ width: '80px' }}
          size="small"
        />
      </Box>

      <LoraEditMenu
        name={lora.name}
        gender={lora.sex}
        lora={lora}
        handleRemoveLora={handleRemoveLora}
      ></LoraEditMenu>
    </ListItem>
  );
};

const LoraDialog = ({ lorasList, loraStrength, onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const lorasList = useSelector((state) => state.generate.loras);
  const open = useSelector((state) => state.generate.lorasDialogOpen);
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const selectedCount = useSelector(
    (state) => state.generate.selectedLorasCount
  );
  const checkpoint = useSelector((state) => state.generate.selectedCheckpoint);
  const selectedFluxLoras = useSelector(
    (state) => state.generate.selected_flux_loras
  );

  const { removeLora } = useCms();
  const [loras, setloras] = useState(lorasList);
  const [genderFilter, setgenderFilter] = useState('all');
  const [globalLoraStrength, setglobalLoraStrength] = useState(
    loadedPrompt.params.lorastrength[
      `${loadedPrompt.species}_${loadedPrompt.gender}`
    ]
  );
  const [globalSelect, setglobalSelect] = useState(false);

  const [itemData, setitemData] = useState();
  const [selectedUser, setSelectedUser] = useState('all');
  const count = useRef(0);

  useEffect(() => {
    console.log(` loras on open ${lorasList.filter((l) => l.selected)}`);
    if (open) {
      if (checkpoint === 'flux_dev' || checkpoint === 'flux_schnell') {
        setgenderFilter('male');
      }
      setloras(lorasList);
      setglobalLoraStrength(
        loadedPrompt.params.lorastrength[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ]
      );
      handleGlobalStrengthUpdate(
        loadedPrompt.params.lorastrength[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ]
      );
    }
  }, [open]);

  const handleClose = () => {
    const lorasToReturn = loras.filter((l, index) => l.selected === true);
    console.log(lorasToReturn);

    onClose(lorasToReturn, globalLoraStrength);
  };

  const handleStrengthUpdate = (value, lora) => {
    setloras((current) => [
      ...current.map((l) =>
        l === lora ? { ...l, strength: parseFloat(value) } : l
      ),
    ]);
    dispatch(
      setLoadedPrompt({
        key: [
          'params',
          'lorastrength',
          `${loadedPrompt.species}_${loadedPrompt.gender}`,
        ],
        data: parseFloat(value),
      })
    );
  };

  const handleGlobalStrengthUpdate = (value) => {
    console.log('global strength chabged');
    dispatch(setLoraStrength(value));
    setglobalLoraStrength(value);
    // setLoraStrength(value);
    setloras((current) => [
      ...current.map((l) => ({ ...l, strength: parseFloat(value) })),
    ]);
    dispatch(
      setLoadedPrompt({
        key: [
          'params',
          'lorastrength',
          `${loadedPrompt.species}_${loadedPrompt.gender}`,
        ],
        data: parseFloat(value),
      })
    );
  };

  const handleSelectLora = (value, lora) => {
    setloras((current) => [
      ...current.map((l) => (l === lora ? { ...l, selected: value } : l)),
    ]);
    dispatch(
      setLoras(loras.map((l) => (l === lora ? { ...l, selected: value } : l)))
    );
  };

  const handleRemoveLora = (lora) => {
    removeLora(lora).then((resp) => {
      if (resp.success) {
        setloras(loras.filter((item) => item !== lora));
      }
    });
  };

  const handleSelectAll = (value) => {
    setglobalSelect(value);
    if (genderFilter === 'all') {
      if (selectedUser === 'all') {
        setloras((current) => [
          ...current.map((l) => ({ ...l, selected: value })),
        ]);
      } else {
        setloras((current) => [
          ...current.map((l) =>
            l.user === selectedUser ? { ...l, selected: value } : l
          ),
        ]);
      }
    } else {
      if (selectedUser === 'all') {
        setloras((current) => [
          ...current.map((l) =>
            l.sex === genderFilter ? { ...l, selected: value } : l
          ),
        ]);
      } else {
        setloras((current) => [
          ...current.map((l) =>
            l.sex === genderFilter && l.user === selectedUser
              ? { ...l, selected: value }
              : l
          ),
        ]);
      }
    }
  };

  useEffect(() => {
    if (loras) {
      console.log('loras changed');
      countSelected();
      updateLoras();
    }
  }, [loras, genderFilter, selectedUser]);

  const updateLoras = () => {
    const id = createItemData(
      loras.filter((l) => {
        if (genderFilter === 'all' && selectedUser === 'all') {
          return l;
        } else {
          if (genderFilter === 'all' && selectedUser !== 'all') {
            return l.user === selectedUser;
          }
          if (genderFilter !== 'all' && selectedUser !== 'all') {
            return l.sex === genderFilter && l.user === selectedUser;
          }
          if (genderFilter !== 'all' && selectedUser === 'all') {
            return l.sex === genderFilter;
          }
        }
      }),
      handleSelectLora,
      handleStrengthUpdate,
      handleRemoveLora
    );
    setitemData(id);
  };

  const countSelected = () => {
    var selected = { male: 0, female: 0, cat: 0, dog: 0 };
    loras.map((l) => {
      if (l.selected) {
        // console.log('x');
        // selected[l.species === 'human' ? l.gender : l.species] += 1;
        selected[l.sex] += 1;
      }
    });
    dispatch(setSelectedLorasCount(selected));
  };

  const createItemData = memoize(
    (
      loras,
      handleSelectLora,
      handleStrengthUpdate,
      handleRemoveLora,
      genderFilter
    ) => ({
      loras,
      handleSelectLora,
      handleStrengthUpdate,
      handleRemoveLora,
      genderFilter,
    })
  );
  // const createItemData = ({
  //   loras,
  //   handleSelectLora,
  //   handleStrengthUpdate,
  //   handleRemoveLora,
  //   genderFilter,
  // }) => ({
  //   loras,
  //   handleSelectLora,
  //   handleStrengthUpdate,
  //   handleRemoveLora,
  //   genderFilter,
  // });

  // const rowHeights = new Array(itemData.loras.length).fill(true).map(() => 117);

  // const getItemSize = (index) => rowHeights[index];

  const extraLoras = [
    { name: 'flux_realism_lora.safetensors', selected: false, strength: 1 },
    {
      name: 'araminta_k_flux_film_foto.safetensors',
      selected: false,
      strength: 1,
    },
    { name: 'paradiffflux.safetensors', selected: false, strength: 1 },
  ];

  const handleSelectFluxLora = (index, selected, strength) => {
    var sel = JSON.parse(JSON.stringify(selectedFluxLoras));
    sel[index].selected = selected;
    sel[index].strength = strength;
    dispatch(setSelectedFluxLoras(sel));
    // dispatch(
    //   setSelectedFluxLoras(typeof value === 'string' ? value.split(',') : value)
    // );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'xl'}
      sx={{
        backdropFilter: 'blur(2px)',
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.background.default, // Change background color here
        },
      }}
    >
      {/* {checkpoint === 'flux_dev' || checkpoint === 'flux_schnell' ? (
        <Box
          sx={{
            backgroundColor: '#fecd27',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            color: 'red',
            width: '100%',
            fontSize: '20px',
          }}
        >
          !!! Currently only MALE and FEMALE loras are available for flux !!!
        </Box>
      ) : (
        <></>
      )} */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '20px',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={genderFilter}
              onChange={(e) => setgenderFilter(e.target.value)}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="cat" control={<Radio />} label="Cat" />
              <FormControlLabel value="dog" control={<Radio />} label="dog" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Dropdown
            name="user"
            value={selectedUser}
            setValue={setSelectedUser}
            items={[
              'all',
              ...new Set(
                loras
                  .map((lora) => lora.user)
                  .sort((a, b) => {
                    return a.localeCompare(b, undefined, {
                      sensitivity: 'base',
                    });
                  })
              ),
            ]}
            width={200}
          ></Dropdown>
        </Box>
        {checkpoint === 'flux_dev' || checkpoint === 'flux_schnell' ? (
          <Box>
            <CheckDropdown
              title="extra loras"
              value={selectedFluxLoras}
              setValue={handleSelectFluxLora}
              items={selectedFluxLoras}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '45px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <TextField
              type="number"
              margin="normal"
              id="globalLoraStrength"
              label="global lora strength"
              name="global lora strength"
              value={globalLoraStrength}
              onChange={(e) => handleGlobalStrengthUpdate(e.target.value)}
              className="ring-0"
              sx={{ width: '130px' }}
              size="small"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          padding: '0px 16px',
          gap: '0px',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Checkbox
          checked={globalSelect}
          onChange={(e) => handleSelectAll(e.target.checked)}
          sx={{ width: '40px' }}
        />
        <Typography sx={{ marginTop: '9px' }}>Select / Unselect All</Typography>
        <Typography
          sx={{ paddingLeft: '20px', marginTop: '9px', color: '#ff6000' }}
        >{`${
          selectedCount.male !== 0
            ? '  Male: ' + selectedCount.male + ' | '
            : ''
        } ${
          selectedCount.female !== 0
            ? '  Female: ' + selectedCount.female + ' | '
            : ''
        } ${
          selectedCount.cat !== 0 ? '  Cat: ' + selectedCount.cat + ' | ' : ''
        } ${
          selectedCount.dog !== 0 ? '  Dog: ' + selectedCount.dog : ''
        }`}</Typography>
      </Box>
      <Divider></Divider>
      {loras?.length !== 0 ? (
        <FixedSizeList
          height={1000}
          itemCount={itemData?.loras.length}
          itemData={itemData}
          itemSize={117}
          width={'100%'}
        >
          {Row}
        </FixedSizeList>
      ) : (
        <div>Loading</div>
      )}
    </Dialog>
  );
};

export default LoraDialog;
