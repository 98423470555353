import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import { Progress, TextWrapCellRenderer } from 'components/SmallComponents';
import { columns } from './columns';

import {
  useGetSubscribersMutation,
  useGetSubscriptionUsageMutation,
} from 'api/apiSlice';

import Toast from 'components/Toast';

function formatDate(inputString) {
  // Parse the input string into a Date object
  const dateObj = new Date(inputString);

  // Format the date components
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export default function DataGridUsers() {
  const apiRef = useGridApiRef();

  const [getUsers, { isLoading }] = useGetSubscribersMutation();
  const [getSubscriptionUsage] = useGetSubscriptionUsageMutation();

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [rows, setrows] = useState([]);

  useEffect(() => {
    async function gr() {
      const usage = await getSubscriptionUsage().unwrap();
      for (let index = 1; index < usage.range + 1; index++) {
        const emptyColumn = {
          field: `d${index - 1}`,
          headerName: `day ${index}`,
          width: 50,
          editable: false,
        };
        apiRef.current.updateColumns([emptyColumn]);
      }

      const users = await getUsers().unwrap();
      const u = users.users.map((r, index) => {
        var temp = { ...r };
        var current_user = usage.data.filter((i) => i._id === r._id)[0];
        if (current_user) {
          current_user.usage_history.forEach((element, index) => {
            temp[`d${index}`] = element;
          });
        } else {
          // for (let index = 1; index < usage.range + 1; index++) {
          //     temp[`d${index}`] = 0;
          // }
        }
        temp.balance = r?.payments.balance;
        temp.id = index;
        temp.created_at = formatDate(r.date);
        if (Array.isArray(r.payments.receipts)) {
          temp.transactions = r.payments.receipts.length;
        } else {
          console.log(typeof r.payments.receipts);
          temp.transactions = 0;
        }
        temp.subscription = r.subscription;

        temp.subscription = r.subscription;
        if (r.subscription?.used_balance) {
          temp.used_balance = r.subscription?.used_balance;
        } else {
          temp.used_balance = 0;
        }

        return temp;
      });
      setrows(u);
    }
    if (rows.length === 0) {
      gr();
    }
  }, []);

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);

  const test = () => {
    setAlert({
      show: true,
      severity: 'success',
      msg: ` test toast message `,
    });
  };
  return (
    <>
      <Toast alert={alert} setAlert={setAlert} />
      <Box sx={{ height: '90vh', width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            row: {
              style: { cursor: 'context-menu' },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                email: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'subscription', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[50]}
          loading={isLoading}
          disableSelectionOnClick
          autoHeight={false}
        />
      </Box>
    </>
  );
}
