import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Camera from './Camera';
import Dropdown from 'components/Dropdown';
import { speciesValues, genderValues } from 'data';
import { useTrainMutation } from 'api/apiSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Training = () => {
  const [train, { isLoading }] = useTrainMutation();
  const username = useSelector((state) => state.authentication.email);

  const [currentTab, setcurrentTab] = useState(0);
  const [images, setImages] = useState([]);
  const [loraName, setloraName] = useState('');
  const [submitted, setsubmitted] = useState(false);
  const [progress, setProgress] = useState('preparing training');

  const [selectedGender, setselectedGender] = useState('male');
  const [selectedSpecies, setselectedSpecies] = useState('human');
  const [sexSwitch, setSexSwitch] = useState(false);

  const [fluxSelected, setFluxSelected] = useState(false);

  const [files, setFiles] = useState(null);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const handleNameChange = (event) => {
    setloraName(event.target.value);
  };
  const submit_train = () => {
    setsubmitted(true);
    const formData = new FormData();
    const data = {
      name: loraName,
      user: 'internal',
      sex: selectedSpecies === 'human' ? selectedGender : selectedSpecies,
      gender: selectedGender,
      species: selectedSpecies,
      flux: fluxSelected,
    };
    for (let i = 0; i < images.length; i++) {
      formData.append('files', images[i]);
    }
    formData.append('data', JSON.stringify(data));
    train(formData).then((resp) => {
      console.log('training in progress');
      if (resp.status === 'success') {
        setsubmitted(false);
        setImages([]);
      }
    });
  };

  const handleTabChange = (event, newValue) => {
    setcurrentTab(newValue);
  };

  const handleUpload = (files) => {
    const arr = [...files];
    arr.map((file) => {
      setImages((current) => [...current, file]);
    });
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab label="Upload" {...a11yProps(0)} />
        {/* <Tab label="Capture" {...a11yProps(1)} /> */}
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <Box
          sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {submitted ? (
            <Box
              sx={{
                width: '100%',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {progress === 'preparing training' ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div className="train-progress">preparing training</div>
                    <CircularProgress />
                  </Box>
                </>
              ) : (
                <div className="train-progress">
                  {progress.completed ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      Training completed
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '600px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      ETA: {progress.left}
                    </Box>
                  )}
                </div>
              )}

              {/* <LinearProgressWithLabel value={progress} /> */}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  mt: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  onChange={(e) => handleUpload(e.target.files)}
                  multiple
                  accept="image/*"
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: ' center',
                  gap: '10px',
                }}
              >
                <TextField
                  margin="normal"
                  id="name"
                  label="lora name"
                  name="lora"
                  value={loraName}
                  onChange={handleNameChange}
                  className="ring-0"
                  sx={{ width: '300px' }}
                  size="small"
                />
                <Dropdown
                  name="species"
                  value={selectedSpecies}
                  setValue={setselectedSpecies}
                  items={speciesValues}
                />
                <Dropdown
                  name="gender"
                  value={selectedGender}
                  setValue={setselectedGender}
                  items={genderValues}
                />
                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        value={fluxSelected}
                        onChange={setFluxSelected}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: 'green', // Example: change the switch knob color when checked
                            '& + .MuiSwitch-track': {
                              backgroundColor: 'lightgreen', // Change the background of the switch track when checked
                            },
                          },
                        }}
                      />
                    }
                    label="flux"
                  />
                </FormGroup> */}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: '8px', width: '100px' }}
                  disabled={loraName === '' ? true : false}
                  onClick={submit_train}
                >
                  {' '}
                  train
                </Button>
              </Box>
            </>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        {submitted ? (
          <Box
            sx={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {progress === 'preparing training' ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className="train-progress">"preparing training"</div>
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <div className="train-progress">
                {progress.completed ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    Training completed
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '600px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    ETA: {progress.left}
                  </Box>
                )}
              </div>
            )}

            {/* <LinearProgressWithLabel value={progress} /> */}
          </Box>
        ) : (
          <>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Camera setImages={setImages} />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: ' center',
                gap: '10px',
              }}
            >
              <TextField
                margin="normal"
                id="name"
                label="lora name"
                name="lora"
                value={loraName}
                onChange={handleNameChange}
                className="ring-0"
                sx={{ width: '300px' }}
                size="small"
              />
              <Dropdown
                name="species"
                value={selectedSpecies}
                setValue={setselectedSpecies}
                items={speciesValues}
              />
              <Dropdown
                name="gender"
                value={selectedGender}
                setValue={setselectedGender}
                items={genderValues}
              />

              <Button
                variant="contained"
                color="primary"
                sx={{ mt: '8px', width: '100px' }}
                disabled={loraName === '' ? true : false}
                onClick={submit_train}
              >
                {' '}
                train
              </Button>
            </Box>
            {/* <Box sx={{ width: '1500px' }}>
              <Gallery images={images} seeds={[]} />
            </Box> */}
          </>
        )}
      </TabPanel>
    </>
  );
};

export default Training;
